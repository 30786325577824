<template>
  <div>
      <!----- 搜索框 start------->
      <el-form :inline="true" class="search">
        <el-form-item label="关键字">
          <el-input v-model="searchData.name" placeholder="关键字搜索" clearable />
        </el-form-item>
          <el-form-item label="收款类型">
            <el-select v-model="searchData.type" placeholder="类型搜索" width="150">
              <el-option label="银行卡" :value="1"></el-option>
              <el-option label="支付宝" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
              <el-button @click="getPersons()">查询</el-button>
          </el-form-item>

          <el-form-item style="float: right">
              <el-button type="primary" @click.stop="clickCreate()">添加收款人</el-button>
          </el-form-item>
      </el-form>
      <!----- 搜索框 end------->

      <!----- 表格 start------->
      <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
          <el-table-column prop="id" label="ID" />
          <el-table-column prop="name" label="姓名" />
          <el-table-column prop="remark" label="备注" />
          <el-table-column prop="account" label="账号" />
          <el-table-column prop="type_desc" label="类型" />
          <el-table-column prop="bank" label="开户行" />
          <el-table-column prop="idcard" label="身份证" />
          <el-table-column prop="mobile" label="手机号" />
          <el-table-column prop="created_at" label="创建时间" />
          <el-table-column label="操作">
              <template #default="scope">
                <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
                <el-button type="success" size="small" @click="clickDestroy(scope.row)">删除</el-button>
              </template>
          </el-table-column>
      </el-table>

      <!----- 表格 end------->


      <!----- 分页 start------->
      <div class="cherry-page" v-show="pageData.total">
          <el-pagination
                  small
                  background
                  layout="prev, pager, next, total"
                  :total="pageData.total"
                  v-model:current-page="searchData.page"
                  @update:current-page="getPersons()"
                  class="mt-4"
          />
      </div>
      <!----- 分页 end------->

      <!-------- 弹出框表单 start ---------->
      <el-dialog v-model="formData.show" title="编辑项目" fullscreen append-to-body>
          <el-form
              ref="person"
              :rules="formData.rules"
              label-width="100px"
              :model="formData.data"

          >
            <el-form-item label="类型" prop="type">
              <el-select v-model="formData.data.type">
                <el-option label="银行卡" :value="1"></el-option>
                <el-option label="支付宝" :value="2"></el-option>
              </el-select>
            </el-form-item>
              <el-form-item label="姓名" prop="name">
                  <el-input v-model="formData.data.name" placeholder="收款人"/>
              </el-form-item>
            <el-form-item label="账号" prop="account">
              <el-input v-model="formData.data.account" placeholder="账号"/>
            </el-form-item>
              <el-form-item label="开户行" prop="bank" v-show="formData.data.type === 1">
                  <el-input v-model="formData.data.bank" placeholder="输入开户行" />
              </el-form-item>
              <el-form-item label="身份证号" prop="idcard">
                  <el-input v-model="formData.data.idcard" placeholder="身份证号码，18位"/>
              </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="formData.data.mobile" placeholder="手机号，11位"/>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="formData.data.remark" placeholder="备注信息，必填"/>
            </el-form-item>
          </el-form>

          <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
          </template>
      </el-dialog>
      <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {apiGetProjectPersons, apiStoreProjectPerson, apiUpdateProjectPerson, apiDestroyProjectPerson} from '@/api/projectPersonApi';
import {getCompanyId} from "@/plugins/company"

  let formData = {
      company_id: getCompanyId(),
      id: 0,
      name: '',
      type: '',
      account: '',
      bank: '',
      idcard: '',
      mobile: '',
      remark: '',
  }

  export default {
      name: 'project.person',
      created () {
        this.getPersons()
      },
      data() {
          return {
              // 创建表单
              formData:{
                  lock: false,
                  data: formData,
                  rules: {
                    name: [{
                      required: true,
                      message: '请输入收款人姓名',
                      trigger: 'blur',
                    }],
                    account: [{
                      required: true,
                      message: '请输入收款的账号',
                      trigger: 'blur',
                    }],
                    type: [{
                      required: true,
                      message: '请选择类型',
                      trigger: 'blur',
                    }],
                    remark: [{
                      required: true,
                      message: '备注信息',
                      trigger: 'blur',
                    }],
                  },
                  show: false,
              },
              tableData: [],
              searchData: {
                  company_id: getCompanyId(),
                  name: '',
                  type: '',
                  page: 1,
              },
              pageData: {
                  total: 0,
              }
          }
      },
      methods: {
          getPersons() {
              apiGetProjectPersons(this.searchData).then((res) => {
                  this.tableData = res.data
                  this.pageData = res.meta
              })
          },
          onSubmit()  {
              if (this.formData.lock) {return;}
              this.formLock()
              this.$refs['person'].validate(valid => {
                  if (valid) {
                      // 更新逻辑
                      if (this.formData.data.id > 0) {
                          apiUpdateProjectPerson(this.formData.data.id, this.formData.data).then(() => {
                              this.$message.success('修改成功')
                              this.getPersons()
                              this.closeDialog()
                              this.formUnlock()
                          }).catch(() => {
                              this.formUnlock()
                          })
                      } else {
                        apiStoreProjectPerson(this.formData.data).then(() => {
                          this.$message.success('创建成功')
                          this.getPersons()
                          this.closeDialog()
                          this.formUnlock()
                        }).catch(() => {
                          this.formUnlock()
                        })
                      }
                  }
              })
          },
          // 点击更新
          clickUpdate (row) {
              this.formData.data = row
              this.showDialog()
          },
        clickDestroy(row){
          this.$confirm('确定删除吗').then(() => {
            apiDestroyProjectPerson(row.id).then(() => {
              this.getPersons()
              this.$message.success('删除成功')
            })
          })
        },
          // 点击创建
          clickCreate () {
              this.initDialog()
              this.showDialog()
          },
          formLock() {
              this.formData.lock = true
          },
          formUnlock() {
              this.formData.lock = false
          },
          formContractLock() {
            this.formData.lock = true
          },
          formContractUnlock() {
            this.formData.lock = false
          },
          // 初始化弹出
          initDialog () {
              this.formData.data = formData
              this.closeDialog()
          },
          // 显示弹窗
          showDialog() {
              this.formData.show = true
          },
          // 关闭弹窗
          closeDialog() {
              this.formData.show = false
          }
      }
  }
</script>
